import './App.css';

function JobBlurb(props) {
  return (
    <div className="Job-blurb">
      <b>{props.title}</b> • {props.company} • {props.from}&mdash;{props.to} 
      {/* <div><br/>Projects</div> */}
    </div>

  )
}

function Link() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.5 3C3.22386 3 3 3.22386 3 3.5C3 3.77614 3.22386 4 3.5 4V3ZM8.5 3.5H9C9 3.22386 8.77614 3 8.5 3V3.5ZM8 8.5C8 8.77614 8.22386 9 8.5 9C8.77614 9 9 8.77614 9 8.5H8ZM2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L2.64645 8.64645ZM3.5 4H8.5V3H3.5V4ZM8 3.5V8.5H9V3.5H8ZM8.14645 3.14645L2.64645 8.64645L3.35355 9.35355L8.85355 3.85355L8.14645 3.14645Z" fill="#000"></path></svg>
  )
}

function Project(props) {
  return (
    <div className="Project">
      <a className="Project-name" rel="noreferrer noopener" href={props.link}>{props.name} <Link/></a>
      <div className="Project-desc-lead">Description</div>
      <div className="Project-desc">
        <ul>
          {props.desc.map(descPoint => <li>{descPoint}</li>)}
        </ul>
      </div>
      <div className="Project-impact-lead">Impact</div>
      <div className="Project-impact">
        <ul>
          {props.impact.map(impactPoint => <li>{impactPoint}</li>)}
        </ul>
      </div>
      <div className="Project-tech">
        <i>Technologies</i>: {props.tech}
      </div>
    </div>
  )
}

function CV() {
  return (
    <div className="CV">
      <h1>Ilia Sidorenko's engineering projects</h1>

      <div className="Job">
        <JobBlurb
          title="Founding Engineer"
          company="Turnout"
          from="May 2019"
          to="Present"
        />

        <Project
          name="MVP of the app"
          link="https://www.turnout.app/"
          desc={[
            'Hired and led a team of 5 to come up with the design and subsequently implement the product',
            'Worked closely with a Designer, a Front-end Developer and a React Native Engineer',
            'Built the infrastructure for the product on AWS using Terraform.',
          ]}
          impact={[
            'The product gained at least 73,400 app installs, allowing the product to capture initial network effects and generate sustained user engagement',
            'The product captured the second place in its niche, being second only to CarMeets Inc (measured by app installs)',
            'The product was relied upon by a loyal set of users'
            ]}
          tech="Objective-C, React Native, Node.js, MySQL, AWS, Swift"
        />

        <Project
          name="Navigation with Turn-by-turn"
          link="https://turnout.app/"
          desc={[
          'Integrated Mapbox\'s Turn-by-turn Navigation into the product using Objective-C and React Native\'s Native Modules',
          'Lead a contractor to implement the Android version of the Native Module using Java',
          'Implemented additional product-specific features such as: adding map markers, displaying map markers and other symbols on the map, voice chat integration, dark mode integration']}
        impact={[
          'The feature was used at least 26,600 times, which increased user engagement and helped drive people to other parts of the app',
          'Multiple users reported using the feature every day',
          'There\'s still no alternative to the Mapbox Navigation SDK integration with React Native that we\'ve built (react-native-mapbox-gl/maps does not offer Turn-by-turn)']}
          tech="React Native, Objective-C"
        />


        <Project
        name="VoIP feature"
        link="https://turnout.app/"
        desc={[
        'Facilitated the design of a room-based VoIP feature similar to Clubhouse with a Designer',
        'Delivered the VoIP feature to market using Agora API with the help of a Front-end engineer']}
       
        impact={[
        'We\'ve seen users creating at least 6,600 committed VoIP sessions, on average lasting 74 minutes, which deepened engagement among our users.',
        'Increased attention from VCs since this feature allowed us to enter the audio space',
        'Users reported using the feature over regular real-life Walkie-Talkies',
        'The delivery of this feature allowed us to launch a big marketing campaign for the product']}

        tech="AWS, React Native, Node.js, MySQL"
        />

        <Project
        name="Friends and Chat System"
        link="https://turnout.app/"
        desc={[
        'Facilitated the design of Friends System for the product similar to Twitter followers or Facebook friends with a Designer ',
        'Facilitated the design of a chat system similar to Instagram DMs with a Designer ',
        'Delivered the Friends and Chat Systems to market with the help of a Front-end engineer ',
        'Integrated existing product features like invitations and activities into the Chat System']}
        
        impact={[
        'At least 47,500 friend relationships were created ',
        'At least 13,500 messages were sent ',
        'The release sparked user interest and increased DAU 55% ',
        'Users reported meeting new people on the app who they would not meet otherwise']}

        tech="MySQL, React Native, AWS, Node.js"
        />

        <Project
        name="Location Tracking feature"
        link="https://turnout.app/"
        desc={[
        'Architected and built the live location tracking feature which allowed people to see their friends on the map ',
        'Linear interpolation and extrapolation was used to approximate the position in-between ticks, which made the tracking very smooth']}
        
        impact={[
        'At least 50% of the company\'s revenue came from selling this feature ',
        'At least 46,400 people tried the feature out ',
        'Users continued being impressed with the feature']}

        tech="Protobuf, Websocket, AWS, Node.js, React Native, MySQL"
        />
      </div>

      <div className="Job">

        <JobBlurb
          title="Senior Software Engineer"
          company="Boardingware"
          from="November 2016"
          to="May 2019"
        />

        <Project
          name="Parent web app workflow extensions"
          link="https://blog.boardingware.com/advanced-custom-leave-types/"
          desc={[
            'Worked closely with the Team Lead, Mobile engineers, Designer and the PM to design and implement new workflows for the leave process ',
            'Closely communicated with the Designer to solve the intricate edge cases and constraints of the existing workflow which had to be supported for all existing customers (the company\'s product being multi-tenant) ',
            'Reviewed the security implications of the feature with the CTO ',
            'Facilitated the deployment of the new feature and post-deployment bug fixes and maintenance']}
          impact={[
            'Key customer was acquired after the feature was shipped ',
            'The feature became one of the selling points in the marketing collateral ',
            'Customer Success reported on existing customers adopting the feature as well']}
          tech="Node.js, Angular, AWS, MySQL"
        />
        <Project
          name="Check-In System"
          link="https://blog.boardingware.com/announcing-checkpoint-manager/"
          desc={[
            'Participated in the development of a company-defining new feature set which was aimed at attacking a new market (a pivot in the product\'s direction) ',
            'Participated in the design review and brainstorming of ideas of how the feature works with the existing functionality and how to work around the edge cases. ',
            'Implemented the backend and the frontend business logic in a team of 6 engineers ',
            'Facilitated the deployment and bug fixes reported by customers ',
            'Helped Customer Success with training for the new functionality']}
          impact={[
            'Hybrid customers who were already in the space picked up the feature after the release and started adopting ',
            'In a few months after the delivery of the feature, new customers were signed up just for the Check-In feature ',
            'The feature became an integral part of the Contact Tracing solution the company started developing further on, laying down a robust foundation for it.']}
          tech="AWS, Angular, Node.js, React"
        />
        <Project
          name="Training 2 new engineers"
          link=""
          desc={[
            'Trained 2 graduate interns that were hired for the summer ',
            'Lead their ramp-up and taught them the software development lifecycle, the technologies being used at the company and the product knowledge. ',
            'After both of them got a full-time role, I continued to lead and mentor them in other projects.']}
          impact={[
            '4 months after, the interns were hired as junior developers ',
            '6 months after, they were promoted to Mid Level engineers after showing incredible performance and a quick ability to learn ',
            'After I left the company, one was promoted to a Senior Software Engineer and the other left to start his own venture']}
          tech="React, GitHub, Node.js, Angular, MySQL"
        />
        <Project
          name="New Deployment system (CI/CD)"
          link=""
          desc={[
            'Owned the project and was fully responsible for its success, with the CTO only brainstorming the requirements ',
            'Used Docker, CodeDeploy, Bamboo and PM2 to build a CI/CD pipeline ',
            'Keymetrics.io was used for logging ',
            'CloudWatch alerts for paging for production incidents were also implemented']}
          impact={[
            'The number of deployments went up significantly, from a few a month to 5 to 10 a week (10x improvement) ',
            'Deployments became less error-prone, which allowed engineers to have more confidence in bug fix and feature releases ',
            'More observability into infrastructure was achieved']}
          tech="AWS, Node.js, DevOps, Bash"
        />
        <Project
          name="New Frontend for Staff members"
          link="https://blog.boardingware.com/empower-your-boarding-management/"
          desc={[
            'I was part of the team which built a new frontend for the web app. The management\'s vision was that the old version was clunky and dated, and it needed a fresh look. ',
            'Implemented the frontend logic ',
            'Directed a Front-end engineer on how to implement the layout from designs ',
            'Facilitated the release strategy and the switchover']}
          impact={[
            'Sales team gave feedback that the new frontend is easier to sell ',
            'Customer support team gave very positive feedback on the ease of use of the common jobs to be done ',
            'Customers noted the extra polish of the new version']}
          tech="jQuery, Node.js, AWS, Angular"
        />
      </div>

      <div className="Job">
        <JobBlurb
          title="Web Developer"
          company="CarJam"
          from="December 2015"
          to="January 2017"
        />
        <Project
          name="Japanese imports report"
          link="https://www.carjam.co.nz/about-report/"
          desc={[
            'A new type of report (Japanese imports) that customers could order had to be implemented. ',
            'Implemented the front-end layout ',
            'Implemented the fetching the reports from a data vendor']}
          impact={['Japanese imports report feature was shipped ',
            'Management mentioned that customers started ordering the reports, and the company had a revenue uplift.']}
          tech="CSS, PHP, HTML"
        />

        <Project
          name="Photos feature improvements"
          link="https://www.carjam.co.nz/photos/"
          desc={[
            'Made improvements to the photos feature including the responsive layout, filtering and more modern look']}
          impact={[
            'Management appreciated the ease of use of the new version ',
            'Since the deployment of the new version, the number of hosted photos grew substantially']}
          tech="HTML, CSS, jQuery, PHP"
        />

        <Project
          name="Most popular cars feature"
          link="https://www.carjam.co.nz/nz-fleet/"
          desc={[
            'Developed a frontend for the list of most popular cars in the NZ fleet based on an existing database ',
            'Integrated the backlinks into existing reports so that the feature can be found']}
          impact={['The feature extended the completeness of the CarJam offering and made reports look more substantial']}
          tech="HTML, CSS, jQuery, PHP"
        />

        <Project
          name="New PPSR API joint working group"
          link="https://api.business.govt.nz/api/apis/info?name=PPSR&version=v1&provider=mbiecreator"
          desc={[
            'Working with the government to define the new Personal Property Securities Register API to use at CarJam ',
            'As one of the main users of the API, CarJam was invited to review and implement the draft version of the REST API spec to see if it satisfies the use case. ',
            'Reviewed the Swagger REST Api definition and implemented the draft prototypes of the API to ensure we receive all the data']}
          impact={[
            'Changes to the API have been communicated to the govt API team and were subsequently implemented ',
            'The API was shipped, and when the time came to implementing the production version we had all the data we needed']}
          tech="MySQL, PHP"
        />
      </div>

      <div className="Job">

        <JobBlurb
          title="Front-end developer"
          company="Kiwi-Coin"
          from="December 2014"
          to="December 2015"
        />
        <Project
          name="Stats feature"
          link="https://kiwi-coin.com/stats.html"
          desc={['Used Plotly.js library to build a stats feature with a candle-stick graph, transaction log and transaction volume']}
          impact={['Users were able to see that valuable trading information at a glance, which allowed them to see the performance of the market and the exchange much easier.']}
          tech="jQuery, CSS, HTML"
        />
        <Project
          name="Recent news feature"
          link="https://kiwi-coin.com/"
          desc={[
            'Built a feature which allowed the admin to add recent news and announcements and for users to see them',
            'Users could mark the news as read and not get notified about that news again']}
          impact={['A communication channel with the user base was established, with the feature used on a lot of occasions to alert of the exchange\'s important news.']}
          tech="HTML, jQuery, CSS"
        />
        <Project
          name="Custom help pages system"
          link="https://kiwi-coin.com/help.html"
          desc={['Built a custom layout for help pages which improved the explanation of the complicated AML/KYC process the customers had to go through']}
          impact={['Customers appreciated the tree structure of the verification levels and were less confused about which level they should go for and how to get verified']}
          tech="jQuery, HTML, CSS"
        />
        <Project
          name="User Chat"
          link="https://kiwi-coin.com/communication.html#!/private_messages/"
          desc={['Built a user chat feature which allowed the users to chat with each other privately']}
          impact={[
            'The feature was welcomed by the users with positive feedback',
            'The same Vue.js component was also reused for Customer Support feature from the exchange\'s personnel side.']}
          tech="Vue.js, CSS, jQuery, HTML"
        />

      </div>

      <div className="Footer">
        <a href="/">← Back to noway.codes</a>
      </div>

    </div>
  );
}

export default CV;
