function App(props) {
  return (
    <div className="App">
      {/* <h1>Ilia Sidorenko</h1> */}
      {/* <div> */}
      {/*   <a href="/cv">CV</a> */}
      {/* </div> */}
      <div>
        <a href="/portfolio">Portfolio</a>
      </div>
      {/* <div> */}
      {/*   <a href="/awards">Awards</a> */}
      {/* </div> */}
      <div>
        <a href="https://git.io/way">My personal website</a>
      </div>
      {/* <div> */}
      {/*   <a href="/links">Links</a> */}
      {/* </div> */}
    </div>  
  )
}
export default App
